@tailwind base;
@tailwind components;

@import "Front/css/additional-styles/utility-patterns.css";
@import "Front/css/additional-styles/flatpickr.css";

@tailwind utilities;


.text-nowrap{
    white-space: nowrap !important;
}